import classNames from "classnames";
import React from "react";
import Image from "./Image";
import Link from "./Link";
const Figure = ({ illustration, className }) => {
  return (
    <figure className={classNames("relative", className)}>
      <Image
        image={illustration.image}
        // objectFit="contain"
        // objectPostion="left top"
      />
      {(illustration.label || illustration.title) && (
        <figcaption className="absolute bottom-0 right-0 left-0 p-10 bg-gradient-to-b from-black/0 to-black/90">
          {illustration.label && (
            <p className="text-lg">{illustration.label}</p>
          )}
          {illustration.title && (
            <h3 className="text-3xl">{illustration.title}</h3>
          )}
        </figcaption>
      )}
    </figure>
  );
};

const Illustration = ({ illustration, className }) => {
  return illustration.linkUrl ? (
    <Link
      className={classNames("transition-all hover:brightness-110", className)}
      href={illustration.linkUrl}
    >
      <Figure illustration={illustration} />
    </Link>
  ) : (
    <Figure className={className} illustration={illustration} />
  );
};

export default Illustration;
