import classNames from "classnames";
import React from "react";
import Image from "../ui/Image";

export default function TeamMembers({ heading, members = [] }) {
  return (
    <section className="my-24 lg:my-40">
      <div className="container">
        {heading && (
          <h2
            className="text-2xl md:text-4xl	font-bold mb-10 text-center sm:text-left"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        {members.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-16 gap-x-8 items-start">
            {members.map(({ _id, image, name, description }, index) => {
              return (
                <div
                  key={_id}
                  className={classNames("group relative lg:even:my-10", {
                    "xl:even:my-0": ![1, 3, 6, 8].includes(index),
                    "xl:my-10": [1, 3, 6, 8].includes(index),
                  })}
                >
                  {image && (
                    <figure className="">
                      <Image
                        image={image}
                        alt={[name, description].join(" - ")}
                        options={{ width: 600 }}
                        className="grayscale brightness-75 bright transition-all duration-500 group-hover:brightness-105 group-hover:-translate-y-1"
                      />
                    </figure>
                  )}
                  <div className="opacity-0 absolute top-full group-hover:opacity-100 duration-500  group-hover:-translate-y-4 transition-all">
                    <h4 className="text-blue text-base font-normal leading-none uppercase tracking-widest">
                      {name}
                    </h4>
                    <p>{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
}
