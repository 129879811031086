import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import PageLayout from "../components/common/PageLayout";
import PageSectionRenderer from "../components/common/PageSectionRenderer";

const PageTemplate = ({ data, errors }) => {
  const page = data && data.page;

  return (
    <>
      {page && (
        <PageLayout {...page}>
          <main className="overflow-hidden">
            <h1
              className={classNames(
                {
                  "container my-40 text-6xl text-center font-bold":
                    page.showTitle,
                },
                { "sr-only": !page.showTitle }
              )}
            >
              {page.title}
            </h1>
            <PageSectionRenderer
              sections={page._rawContent}
              content={page.content}
            />
          </main>
        </PageLayout>
      )}
    </>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      _rawContent(resolveReferences: { maxDepth: 10 })
      content {
        ... on SanityPressSection {
          _key
          _type
          edition {
            pressReleases {
              _id
              image {
                asset {
                  _id
                }
              }
              publicationDate
              source
              title
              url
            }
          }
        }
      }
      metaTitle
      metaDescription
      slug {
        current
      }
      title
      showTitle
    }
  }
`;
