import loadable from "@loadable/component";
import React, { useRef, useState } from "react";
import Pagination from "../common/Pagination";

const LazyPressReleases = loadable(() => import("../blocks/PressReleases"));
const PressReleases = (props) => <LazyPressReleases {...props} />;

export default function PressSection({
  heading,
  edition,
  releasesPerPage = 12,
}) {
  const contentRef = useRef(null);
  const { pressReleases } = edition;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRecord = currentPage * releasesPerPage;
  const indexOfFirstRecord = indexOfLastRecord - releasesPerPage;
  const currentRecords = pressReleases.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(pressReleases.length / releasesPerPage);

  return (
    <section className="my-24 lg:my-40 scroll-m-8" ref={contentRef}>
      <div className="container">
        {heading && (
          <h2
            className="text-2xl md:text-4xl	font-bold mb-10 text-center sm:text-left"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        <PressReleases
          pressReleases={currentRecords}
          currentPage={currentPage}
        />
        {nPages > 1 ? (
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            contentRef={contentRef}
          />
        ) : null}
      </div>
    </section>
  );
}
