import loadable from "@loadable/component";
import React from "react";
import LinkGroup from "../ui/LinkGroup";

const LazyLogoSlider = loadable(() => import("../blocks/LogoSlider"));
const LogoSlider = (props) => <LazyLogoSlider {...props} />;

const LogosSection = ({ content, logos }) => {
  const { heading, linkGroup, text } = content;

  return (
    <section className="my-24 lg:my-48">
      <div className="container">
        <div className="mb-16 flex flex-col lg:flex-row lg:items-center justify-between">
          {heading && (
            <h2
              className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 min-w-[8em] lg:max-w-sm mr-12 lg:mb-0 lg:flex-none"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          )}
          {text && (
            <p className="whitespace-pre-line text-base md:text-lg lg:max-w-xl">
              {text}
            </p>
          )}
          <LinkGroup
            className="flex-none text-center mt-8 lg:mt-0 lg:ml-auto"
            links={linkGroup?.links}
          />
        </div>
        <LogoSlider logos={logos} />
      </div>
    </section>
  );
};

export default LogosSection;
