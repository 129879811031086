import { PortableText } from "@portabletext/react";
import React from "react";

export default function BlockContent(props) {
  return (
    !!props.value && (
      <div className={props.className}>
        <PortableText {...props} />
      </div>
    )
  );
}
