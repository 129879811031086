import React from "react";
import BlockContent from "../ui/BlockContent";
import Illustration from "../ui/Illustration";
import LinkGroup from "../ui/LinkGroup";

export default function TextSection({ content }) {
  return (
    <section className="my-24 lg:my-40">
      <div className="container">
        <BlockContent value={content} components={components} className="" />
      </div>
    </section>
  );
}

const components = {
  block: {
    h2: ({ children }) => <h2 className="text-4xl mb-8">{children}</h2>,
    h3: ({ children }) => <h3 className="text-3xl mb-8">{children}</h3>,
    h4: ({ children }) => <h4 className="text-2xl mb-8">{children}</h4>,
    normal: ({ children }) => <p className="mb-6 md:text-lg">{children}</p>,
    large: ({ children }) => (
      <p className="text-lg mb-6 md:text-xl">{children}</p>
    ),
  },
  list: {
    bullet: ({ children }) => (
      <ul className="mb-6 list-disc list-outside pl-6 md:text-lg">
        {children}
      </ul>
    ),
    number: ({ children }) => (
      <ol className="mb-6 list-decimal list-outside pl-6 md:text-lg">
        {children}
      </ol>
    ),
  },
  marks: {
    strong: ({ children }) => {
      return <strong className="font-bold">{children}</strong>;
    },
  },
  types: {
    linkGroup: ({ value }) => {
      return <LinkGroup className="" links={value.links} />;
    },
    illustration: ({ value }) => {
      return <Illustration className="block mt-8" illustration={value} />;
    },
  },
};
