import classNames from "classnames";
import React from "react";
import BlockContent from "../ui/BlockContent";
import Illustration from "../ui/Illustration";
import LinkGroup from "../ui/LinkGroup";
import MediaGroup from "../ui/MediaGroup";

const TextMedia = ({ appearance, content, contentPosition, mediaGroup }) => {
  return (
    <section
      className={classNames(
        appearance && `section-${appearance}`,
        "my-24 lg:my-48 ",
        {
          "py-24 lg:pt-48 pb-44 bg-blue rounded-t-0 rounded-b-4xl relative before:block before:absolute before:top-0 before:right-0 before:left-0 before:bg-black before:h-8 before:rounded-b-4xl":
            appearance === "highlighted",
        }
      )}
    >
      <div
        className={classNames("container", {
          "lg:flex lg:flex-row justify-between": contentPosition !== "right",

          "flex flex-col lg:flex-row-reverse items-center":
            contentPosition === "right",
          "content-end md:flex lg:flex-row": appearance === "bricks",
        })}
      >
        <BlockContent
          value={content}
          components={components(appearance)}
          className={classNames("md:mr-4 lg:mr-8 xl:mr-16 lg:max-w-sm", {
            "text-black": appearance === "highlighted",
            "lg:min-w-[20em] md:mr-0 lg:max-w-md": contentPosition === "right",
            "lg:max-w-md": appearance === "bricks",
          })}
        />
        <MediaGroup
          mediaGroup={mediaGroup}
          sectionAppearance={appearance}
          contentPosition={contentPosition}
        />
      </div>
    </section>
  );
};

const components = (appearance) => {
  return {
    block: {
      h2: ({ children }) => (
        <h2 className="text-3xl lg:text-5xl mb-8">{children}</h2>
      ),
      h3: ({ children }) => (
        <h3 className="text-xl lg:text-4xl mb-8">{children}</h3>
      ),
      h4: ({ children }) => (
        <h4 className="text-lg lg:text-3xl mb-8">{children}</h4>
      ),
      normal: ({ children }) => (
        <p className="text-base md:text-lg mb-6 lg:mb-8">{children}</p>
      ),
      large: ({ children }) => (
        <p className="text-2xl mb-6 lg:mb-8">{children}</p>
      ),
    },
    list: {
      bullet: ({ children }) => (
        <ul className="mb-8 list-disc list-outside pl-6">{children}</ul>
      ),
      number: ({ children }) => (
        <ol className="mb-8 list-decimal list-outside pl-6">{children}</ol>
      ),
    },
    marks: {
      strong: ({ children }) => {
        return (
          <strong
            className={`font-bold ${
              appearance === "highlighted" ? "text-white" : ""
            }`}
          >
            {children}
          </strong>
        );
      },
    },
    types: {
      linkGroup: ({ value }) => {
        return <LinkGroup className="text-center" links={value.links} />;
      },
      illustration: ({ value }) => {
        return <Illustration className="block mt-8" illustration={value} />;
      },
    },
  };
};

export default TextMedia;
