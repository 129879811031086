import React from "react";
import LinkGroup from "../ui/LinkGroup";

export default function IntroSection({ content }) {
  const { heading, linkGroup, text } = content;
  return (
    <section className="bg-black py-20 lg:py-28 -mt-4 -mb-24 rounded-2xl relative z-10">
      <div className="container">
        <div className={"md:flex gap-8 flex-row items-center"}>
          {heading && (
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 md:mb-0 min-w-[8em] max-w-sm mr-12 lg:mr-[15%] lg:flex-none">
              {heading}
            </h2>
          )}
          {text && (
            <p className="whitespace-pre-line text-base md:text-lg max-w-4xl bg-black-700 rounded-3xl p-6 lg:p-16">
              {text}
            </p>
          )}
          <LinkGroup
            className="flex-none text-center mt-8 md:mt-0 md:ml-auto"
            links={linkGroup?.links}
          />
        </div>
      </div>
    </section>
  );
}
