import loadable from "@loadable/component";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Illustration from "./Illustration";
import Image from "./Image";
import TileItem from "./TileItem";
const LazyVideo = loadable(() => import("./Video"));
const Video = (props) => (!isMobile ? <LazyVideo {...props} /> : null);

const MediaGroup = ({
  mediaGroup,
  className,
  sectionAppearance,
  contentPosition,
}) => {
  const { media, logo, tiles } = mediaGroup || {};

  const [detectedMobile, setDetectedMobile] = useState(false);

  useEffect(() => {
    setDetectedMobile(isMobile);
  }, []);

  if (!(media || []).length) {
    return;
  }

  const hasFilm = mediaGroup.media.some((item) => item._type === "film");

  const MediaItemIllustration = (item) => (
    <Illustration
      key={item._key}
      illustration={item}
      className={classNames(
        "block rounded-2xl xl:rounded-4xl overflow-hidden relative z-10",
        {
          "first:xl:w-1/2 first:mr-4 first:lg:mr-10 first:[:nth-last-of-type(3)]:h-1/2 last-of-type:absolute last-of-type:inset-0 last-of-type:z-0 last-of-type:-bottom-40":
            sectionAppearance !== "bricks",
          "max-h-[10rem] md:max-h-full flex w-full lg:w-auto flex-1":
            sectionAppearance === "bricks" && !hasFilm,
          "first::mr-0": contentPosition === "right",
          "first:absolute first:w-1/3 first:-left-4 first:bottom-[40%] first:z-30 z-20 first:border-blue first:border-[.5rem] first:rounded-3xl xl:first:rounded-4xl lg:first:border-[1rem]":
            hasFilm,
          "last-of-type:left-auto last-of-type:top-auto last-of-type:-bottom-40":
            hasFilm,
          "even:w-[88%] even:ml-[12%] even:max-w-[50rem]":
            hasFilm && !detectedMobile,
        }
      )}
    />
  );

  return (
    <div
      className={classNames(
        "flex flex-row justify-center items-end relative justify-items-center flex-1",
        className,
        {
          "mt-10 lg:mt-0": !hasFilm,
          "lg:mr-[10%] items-center flex-1 flex flex-col my-20 lg:mt-0":
            contentPosition === "right",
          "flex flex-col flex-wrap sm:flex-row gap-x-8 gap-y-4 md:flex-col lg:flex lg:flex-row mt-12 md:mt-0":
            sectionAppearance === "bricks",
          "self-center mt-32 lg:mt-0": hasFilm,
          "max-w-3xl":
            contentPosition !== "right" && sectionAppearance !== "bricks",
        }
      )}
    >
      {logo &&
        (logo.asset.mimeType === "image/svg+xml" ? (
          <figure className="w-full text-right mb-4 opacity-60">
            <img
              src={logo.asset.url}
              alt={logo.alt}
              width={300}
              height={(logo.asset.height * 300) / logo.asset.width}
              className="inline-block"
            />
          </figure>
        ) : (
          <Image image={logo} />
        ))}
      {media.map((mediaItem) => {
        switch (mediaItem._type) {
          case "illustration": {
            return MediaItemIllustration(mediaItem);
          }
          case "film": {
            if (detectedMobile || !mediaItem.videoFile) {
              const mediaCoverItem = {
                ...mediaItem,
                image: mediaItem.videoCover,
              };
              return MediaItemIllustration(mediaCoverItem);
            }

            return (
              <Video
                key={mediaItem._key}
                className="rounded-2xl xl:rounded-4xl w-[88%] ml-[12%] max-w-[50rem] relative z-10"
                autoPlay
                muted
                loop
              >
                <source
                  src={mediaItem.videoFile.asset.url}
                  type={mediaItem.videoFile.asset.mimeType}
                />
              </Video>
            );
          }
          default:
            return null;
        }
      })}
      {tiles && tiles.length > 0 ? (
        <>
          {tiles.map((tile) => (
            <TileItem key={tile._key} appearance="secondary" {...tile} />
          ))}
        </>
      ) : null}
    </div>
  );
};

export default MediaGroup;
