import classNames from "classnames";
import React from "react";
import Image from "./Image";

export default function TileItem({
  className,
  name,
  value,
  description,
  image,
  appearance = "primary",
}) {
  return (
    <div
      className={classNames(
        "flex flex-col font-bold text-[27px] xl:text-[4rem] leading-none rounded-2xl xl:rounded-4xl py-3 px-5 xl:px-12 xl:py-8",
        {
          "items-center text-center text-white bg-black-700":
            appearance === "primary",
          "min-w-[3.25em] inline-flex absolute bg-white text-black z-30 first-of-type:-top-0 first-of-type:-translate-y-1/2 first-of-type:right-[10%] last-of-type:right-[50%] last-of-type:bottom-[-1.5em]":
            appearance === "secondary",
        }
      )}
    >
      {image ? (
        image.asset.mimeType === "image/svg+xml" ? (
          <img
            src={image.asset.url}
            alt={`${name} icon`}
            height={image.asset.height}
            width={image.asset.width}
            className="mb-2"
          />
        ) : (
          <Image image={image} className="mb-2" />
        )
      ) : null}

      <div className="flex flex-col-reverse gap-y-1">
        <span className="font-medium text-sm xl:text-lg">{name}</span>
        <span className="font-extrabold">{value}</span>
      </div>
      {description ? (
        <span className="text-xs md:text-sm font-light leading-tight mt-4 md:mt-6 lg:mt-7 text-[1rem] block">
          {description}
        </span>
      ) : null}
    </div>
  );
}
