import classNames from "classnames";
import React from "react";

const Pagination = ({ nPages, currentPage, setCurrentPage, contentRef }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const setPage = (pgNumber) => {
    contentRef.current.scrollIntoView();
    setCurrentPage(pgNumber);
  };
  const nextPage = () => {
    if (currentPage !== nPages) setPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setPage(currentPage - 1);
  };
  return (
    <nav className="my-10">
      <ul className="flex  flex-row justify-center gap-x-4">
        <li
          className={classNames("transition-all", {
            "opacity-30 pointer-events-none": currentPage === 1,
            "hover:text-blue": currentPage !== 1,
          })}
        >
          <button
            className="h-12 w-12 rounded-full border text-blue border-black-600 hover:border-blue"
            onClick={prevPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 m-auto block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={classNames("transition-all text-blue", {
              "text-blue pointer-events-none": currentPage === pgNumber,
            })}
          >
            <button
              className={classNames(
                "h-12 w-12 rounded-full border hover:border-blue",
                { "border-blue": currentPage === pgNumber },
                { "border-black-600": currentPage !== pgNumber }
              )}
              onClick={() => setPage(pgNumber)}
            >
              {pgNumber}
            </button>
          </li>
        ))}
        <li
          className={classNames("transition-all", {
            "opacity-30 pointer-events-none": currentPage === nPages,
            "hover:text-blue": currentPage !== nPages,
          })}
        >
          <button
            className="h-12 w-12 rounded-full border transition-all text-blue border-black-600 hover:border-blue"
            onClick={nextPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 m-auto block"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
