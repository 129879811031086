import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Image from "../ui/Image";
const LazyVideo = loadable(() => import("../ui/Video"));
const Video = (props) => (!isMobile ? <LazyVideo {...props} /> : null);

export default function HeroSection({
  animationDuration = 10,
  heading,
  image,
  label,
  videoFile,
  mediaBrightness = 50,
}) {
  const [detectedMobile, setDetectedMobile] = useState(false);
  useEffect(() => {
    setDetectedMobile(isMobile);
  }, []);

  return (
    <section className="bg-black flex flex-col content-between items-center relative justify-between h-screen overflow-hidden">
      {label && (
        <div className="text-[20px] md:text-[42px] font-bold z-30 text-white mt-6">
          {label}
        </div>
      )}
      {heading && (
        <h2
          className="text-[6rem] [word-spacing:1em] md:text-[12rem] whitespace-nowrap leading-none tracking-tight font-extrabold absolute z-30 l-0 bottom-12 text-white animate-slide w-[200vw]"
          style={{
            animationDuration: `${animationDuration}s`,
          }}
        >
          {heading}
        </h2>
      )}
      {image && (
        <Image
          image={image}
          className={"absolute z-10 w-auto min-w-full min-h-full max-w-none"}
          style={{
            filter: `brightness(${mediaBrightness / 100})`,
            display: !videoFile || detectedMobile ? "inline-block" : "none",
          }}
          alt="Hero image in the background."
        />
      )}
      {videoFile && (
        <Video
          autoPlay
          preload="none"
          muted
          loop
          className="absolute z-20 w-auto min-w-full min-h-full max-w-none"
          style={{ filter: `brightness(${mediaBrightness / 100})` }}
        >
          <source src={videoFile.asset.url} type={videoFile.asset.mimeType} />
        </Video>
      )}
    </section>
  );
}
