import classNames from "classnames";
import React from "react";
import StyledLink from "./StyledLink";

export default function LinkGroup({ links, className }) {
  if (!links) {
    return;
  }

  return (
    <ul className={classNames("md:w-fit", className)}>
      {links.map((link) => (
        <li key={link._key} className="my-3 md:text-center last:mb-0">
          <StyledLink
            to={link.url}
            appearance={link.appearance}
            children={link.text}
          />
        </li>
      ))}
    </ul>
  );
}
