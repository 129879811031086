import loadable from "@loadable/component";
import React from "react";

const LazyTestimonialSlider = loadable(() =>
  import("../blocks/TestimonialSlider")
);
const TestimonialSlider = (props) => <LazyTestimonialSlider {...props} />;

export default function TestimonialSection({ heading, testimonials }) {
  return (
    <section className="my-24 lg:my-40">
      <div className="container">
        {heading && (
          <h2
            className="text-3xl md:text-5xl	font-bold mb-10 text-center sm:text-left"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        <TestimonialSlider testimonials={testimonials} />
      </div>
    </section>
  );
}
