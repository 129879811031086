import React from "react";
import Link from "./Link";

export default function StyledLink({ appearance, ...props }) {
  const appearanceMap = {
    primary:
      "link-primary text-white bg-blue text-base max-w-sm w-full sm:w-auto inline-block border border-blue rounded-full px-12 py-4 whitespace-nowrap transition-colors hover:bg-opacity-0",
    secondary:
      "link-secondary text-blue text-sm transition-colors hover:text-white my-4",
  };
  const linkClassName = `text-center font-bold ${
    appearanceMap[appearance || "primary"]
  }`;

  return <Link className={linkClassName} {...props} />;
}
