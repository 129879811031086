import { Script } from "gatsby";
import React from "react";

const CodeSnippet = ({ code, type, _key }) => {
  if (type === "js") {
    return <Script id={`snippet-${_key}`}>{`${code}`}</Script>;
  }

  return (
    <div
      id={`snippet-${_key}`}
      className="container"
      dangerouslySetInnerHTML={{ __html: code }}
    />
  );
};

export default CodeSnippet;
