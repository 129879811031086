import React from "react";
import BoxSection from "../sections/BoxSection";
import CodeSnippet from "../sections/CodeSnippet";
import HeroSection from "../sections/HeroSection";
import IntroSection from "../sections/IntroSection";
import LogoSection from "../sections/LogoSection";
import PressSection from "../sections/PressSection";
import StatsSection from "../sections/StatsSection";
import TeamMembers from "../sections/TeamMembers";
import TestimonialSection from "../sections/TestimonialSection";
import TextMedia from "../sections/TextMedia";
import TextSection from "../sections/TextSection";

export default function PageSectionRenderer({ sections, content }) {
  return (sections || []).map((section) => {
    switch (section._type) {
      case "boxSection": {
        return <BoxSection key={section._key} {...section} />;
      }
      case "intro": {
        return <IntroSection key={section._key} {...section} />;
      }
      case "hero": {
        return <HeroSection key={section._key} {...section} />;
      }
      case "logoSection": {
        return <LogoSection key={section._key} {...section} />;
      }
      case "pressSection": {
        // NOTE: This is a workaround using schema modification and taking a piece of section data from page.content field because I couldn't find a way to resolve edition's press releases deeply in page._rawContent
        const pressSection = content.find((s) => s._key === section._key);
        if (pressSection) {
          section.edition.pressReleases = pressSection.edition.pressReleases;
        }
        return <PressSection key={section._key} {...section} />;
      }
      case "statsSection": {
        return <StatsSection key={section._key} {...section} />;
      }
      case "teamMembers": {
        return <TeamMembers key={section._key} {...section} />;
      }
      case "testimonialSection": {
        return <TestimonialSection key={section._key} {...section} />;
      }
      case "textMedia": {
        return <TextMedia key={section._key} {...section} />;
      }
      case "textSection": {
        return <TextSection key={section._key} {...section} />;
      }
      case "codeSnippet": {
        return <CodeSnippet key={section._key} {...section} />;
      }
      default:
        // console.log("UNKNOWN: ", section);
        return null;
    }
  });
}
