import React from "react";
import TileItem from "../ui/TileItem";

export default function StatsSection({ heading, stats = [] }) {
  return (
    <section className="my-24 lg:my-40">
      <div className="container">
        {heading && (
          <h2
            className="text-2xl md:text-4xl	font-bold mb-10 text-center sm:text-left"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        {stats.length > 0 ? (
          <div className="grid sm:grid-cols-2 justify-items-stretch gap-5 lg:gap-8 xl:gap-10">
            {stats.map((stat) => (
              <TileItem key={stat._key} appearance="primary" {...stat} />
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
}
