import React from "react";
import BlockContent from "../ui/BlockContent";
import Illustration from "../ui/Illustration";
import Link from "../ui/Link";

const BoxSection = ({ boxContent }) => {
  return (
    <section className="my-24 lg:my-40">
      <BlockContent
        value={boxContent}
        components={components}
        className="container flex flex-col bg-blue rounded-4xl text-black overflow-hidden lg:flex-row lg:items-center md:justify-between md:gap-x-20 p-6 xl:px-20 md:p-16"
      />
    </section>
  );
};

export default BoxSection;

const components = {
  block: {
    h2: ({ children }) => (
      <h2 className="mb-4 text-3xl xl:text-5xl [&>strong]:text-white lg:mb-0">
        {children}
      </h2>
    ),
    normal: ({ children }) => <p className="text-lg">{children}</p>,
  },
  marks: {
    strong: ({ children }) => {
      return <strong className="font-bold">{children}</strong>;
    },
    link: ({ children, text, value }) => {
      return (
        <Link className="transition-colors hover:text-white" to={value.href}>
          {children}
        </Link>
      );
    },
  },
  types: {
    illustration: ({ value }) => {
      return (
        <Illustration
          className="md:self-center -my-[25%] -mr-[10%] md:-mr-[8%] -lg:mr-20 lg:-my-20"
          illustration={value}
        />
      );
    },
  },
};
